<template>
  <div class="contact">
    <p>
      Currently the best way to reach out is to DM me on Twitter
      <a id="twitter-link" href="https://twitter.com/cory_eth">@cory_eth</a>.
    </p>
    <p>I am available for consulting or projects.</p>
    <p>Happy to provide more information and credentials if you reach out.</p>
  </div>
</template>

<style lang="scss">
#twitter-link {
  text-decoration: none;
}
</style>
