<template>
  <div class="about">
    <p>
      Money Blocks is owned and operated by <span id="ens-name">cory.eth</span>
    </p>
  </div>
</template>

<style lang="scss">
#ens-name {
  font-weight: bold;
}
</style>
